import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { HeroPictureContext } from '@components/hero-picture-context/hero-picture-context';
import Navbar from '@components/navbar/navbar';
import { Footer } from '@components/footer/footer';

interface TitleQuery {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

export const FullScreenLayout: React.FC = ({ children }) => {
  const data = useStaticQuery<TitleQuery>(graphql`
    query TitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <title key="page-title">{data.site.siteMetadata.title}</title>
      <Navbar />
      <HeroPictureContext />
      <motion.div
        key="page-body"
        initial={{
          opacity: 0,
          x: 300,
        }}
        animate={{
          opacity: 1,
          x: 0,
        }}
        exit={{
          opacity: 0,
          x: -300,
        }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 0.15,
        }}
      >
        {children}
      </motion.div>
      <Footer />
    </div>
  );
};
