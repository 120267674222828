import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.fontSize * 3,
  },
  centered: {
    justifyContent: 'center',
  },
  end: {
    justifyContent: 'flex-end',
  },
  facebook: {
    color: '#3B5998',
  },
  linkedIn: {
    color: '#0077B5',
  },
  github: {
    color: '#000',
  },
}));

export const SocialIcons: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const rootClasses = classNames(classes.root, {
    [classes.centered]: !matches,
    [classes.end]: matches,
  });

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      className={rootClasses}
    >
      <Grid item>
        <a href="https://www.facebook.com/bartusiak.k">
          <FontAwesomeIcon
            icon={faFacebookSquare}
            className={classes.facebook}
          />
        </a>
      </Grid>
      <Grid item>
        <a href="https://www.linkedin.com/in/jakub-bartusiak-jba/">
          <FontAwesomeIcon icon={faLinkedin} className={classes.linkedIn} />
        </a>
      </Grid>
      <Grid item>
        <a href="https://github.com/jbartusiak">
          <FontAwesomeIcon icon={faGithubSquare} className={classes.github} />
        </a>
      </Grid>
    </Grid>
  );
};
