import { useContext } from 'react';
import { HeaderContext, HeaderContextIf } from '../contexts/header-context';

export const useSetHeaderContext = ({
  height,
  image,
  title,
  subtitle,
}: Partial<HeaderContextIf>): void => {
  const { setContext } = useContext(HeaderContext);
  setContext({
    height,
    image,
    title,
    subtitle,
  });
};
