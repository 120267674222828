import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { SocialIcons } from '@components/social-icons/social-icons';
import { Section } from '@components/section/section';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  centered: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();

  const theme = useTheme();
  const inMedium = useMediaQuery(theme.breakpoints.up('md'));

  const leftPanelAlign = classNames({
    [classes.centered]: !inMedium,
    [classes.left]: inMedium,
  });

  const rightPanelAlign = classNames({
    [classes.centered]: !inMedium,
    [classes.right]: inMedium,
  });

  return (
    <Section variant="grey">
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12} md={4} key="blog-author" className={leftPanelAlign}>
          <Typography variant="h5">bartusiak</Typography>
          <Typography>
            <strong>ficticious</strong> blog theme
          </Typography>
          <Typography>crafted with ♥ by bartusiak</Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.spacer} />
        <Grid
          item
          xs={12}
          md={4}
          key="social-icons-section"
          className={rightPanelAlign}
        >
          <Typography variant="h5">znajdz mnie</Typography>
          <SocialIcons />
        </Grid>
      </Grid>
    </Section>
  );
};
