import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core';
import { HeaderContext } from '../../contexts/header-context';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    background:
      'linear-gradient(180deg, rgb(52, 58, 64, 0.75) 0%, rgb(52, 58, 64, 0.1) 100%)',
  },
  imageContainer: {
    gridArea: '1/1',
    zIndex: 0,
  },
  imageCover: {
    zIndex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    gridArea: '1/1',
    height: ' 100%',
    width: '100%',
  },
  childrenContainer: {
    gridArea: '1/1',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    zIndex: 2,
    color: '#FFF',
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,

    '& h1': {
      fontFamily: 'Montserrat, serif',
      fontSize: '4rem',
      fontWeight: '400',
      marginBottom: '0.5rem',
      '@media (max-width: 767px)': {
        fontSize: '2rem',
      },
    },

    '& h2': {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 400,
      letterSpacing: theme.spacing(0.1),
    },
  },
}));

export const HeroPictureContext: React.FC = () => {
  const context = useContext(HeaderContext);

  const classes = useStyles();

  const dynamicStyles = {
    minHeight: context.height,
    maxHeight: context.height,
  };

  return (
    <motion.div
      className={classes.root}
      initial={{
        height: context.previousHeight,
      }}
      animate={{
        height: context.height,
      }}
      exit={{
        height: context.height,
      }}
      transition={{
        type: 'spring',
        mass: 0.35,
        stiffness: 75,
        duration: 0.15,
      }}
    >
      <motion.div
        className={classes.imageContainer}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 0.15,
        }}
      >
        {context.image && (
          <GatsbyImage
            alt="decoration"
            image={context.image}
            style={dynamicStyles}
          />
        )}
      </motion.div>
      <motion.div
        className={classes.imageCover}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 0.15,
        }}
      />
      <motion.div
        className={classes.childrenContainer}
        initial={{
          opacity: 1,
          x: '100vw',
        }}
        animate={{
          opacity: 1,
          x: 0,
        }}
        exit={{
          opacity: 1,
          x: '-100vw',
        }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 0.15,
        }}
      >
        <h1>{context.title}</h1>
        <h2>{context.subtitle}</h2>
      </motion.div>
    </motion.div>
  );
};
