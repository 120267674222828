import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ThemeAugument } from '../../styles/theme-augument';

const useStyles = makeStyles(({ spacing, palette }: ThemeAugument) => ({
  root: {
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
    minHeight: spacing(20),
  },
  contained: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  left: {
    alignItems: 'flex-start',
  },
  center: {
    alignItems: 'center',
  },
  right: {
    alignItems: 'flex-end',
  },
  bgDark: {
    color: palette.dark.contrastText,
    backgroundColor: palette.dark.main,
  },
  bgGreen: {
    color: palette.green.contrastText,
    backgroundColor: palette.green.main,
  },
  bgGrey: {
    color: palette.lightGrey.contrastText,
    backgroundColor: palette.lightGrey.main,
  },
}));

interface SectionProps {
  variant?: 'grey' | 'green' | 'dark';
  alignment?: 'left' | 'center' | 'right';
  contained?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  children,
  variant,
  alignment,
  contained,
}) => {
  const classes = useStyles();

  const rootClassnames = classNames(classes.root, {
    [classes.bgGrey]: variant && variant === 'grey',
    [classes.bgGreen]: variant && variant === 'green',
    [classes.bgDark]: variant && variant === 'dark',
    [classes.contained]: !!contained,
  });

  const containerClassnames = classNames(classes.container, {
    [classes.left]: alignment === 'left',
    [classes.center]: alignment === 'center',
    [classes.right]: alignment === 'right',
  });

  return (
    <div className={rootClassnames}>
      <Container maxWidth="lg" className={containerClassnames}>
        {children as React.ReactChildren}
      </Container>
    </div>
  );
};
