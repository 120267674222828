import React, { useState } from 'react';
import {
  AppBar,
  Container,
  createStyles,
  makeStyles,
  Toolbar,
  useScrollTrigger,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Collapse,
  Link,
} from '@material-ui/core';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import { Navigation } from '@models/Navigation';
import { useAllNavigation } from '../../queries/use-all-navigation';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '1rem',
      transition: 'background-color 500ms',
      flexWrap: 'wrap',
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    link: {
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    title: {
      flexGrow: 1,
    },
    allowWrap: {
      flexWrap: 'wrap',
    },
    secondLine: {
      width: '100%',
    },
    flexOrdering: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

const Navbar = (): JSX.Element => {
  const classes = useStyles();
  const navigationItems = useAllNavigation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);

  const trigger = useScrollTrigger({
    threshold: 10,
    disableHysteresis: true,
  });

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const handleNavigateAway = () => {
    setExpanded(false);
  };

  const createNavItems = ({ navigation }: Navigation) =>
    navigation.nodes.map(({ href, text }) => (
      <Button
        color="inherit"
        key={href}
        onClick={handleNavigateAway}
        component={GatsbyLink}
        to={href}
      >
        {text}
      </Button>
    ));

  return (
    <AppBar
      position="fixed"
      className={classNames(classes.root, {
        [classes.transparent]: !trigger && !expanded,
      })}
      elevation={0}
    >
      <Container maxWidth="lg">
        <Toolbar
          className={classNames({
            [classes.allowWrap]: matches,
          })}
        >
          <Typography className={classes.title} variant="h4" color="inherit">
            <Link to="/" className={classes.link} component={GatsbyLink}>
              bartusiak
            </Link>
          </Typography>
          {matches && (
            <Button variant="outlined" color="inherit" onClick={handleClick}>
              więcej
            </Button>
          )}
          <Collapse
            className={classNames({
              [classes.secondLine]: matches,
            })}
            in={expanded || !matches}
          >
            <nav
              className={classNames({
                [classes.flexOrdering]: matches,
              })}
            >
              {createNavItems(navigationItems)}
            </nav>
          </Collapse>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
