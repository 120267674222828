import { graphql, useStaticQuery } from 'gatsby';
import { Navigation } from '@models/Navigation';

export const useAllNavigation = (): Navigation =>
  useStaticQuery(graphql`
    query AllNavigationQuery {
      navigation: allNavigation(sort: { fields: order, order: ASC }) {
        nodes {
          text
          href
        }
      }
    }
  `);
